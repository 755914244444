import React from "react";
import axios from "../request";
import { emailPattern } from "../utils/email-pattern";

export function RequestPasswordReset(props) {
  const [email, setEmail] = React.useState("");

  function emailChangeHandler(event) {
    setEmail(event.target.value);
  }

  function sendLink() {
    axios.post('/password-reset-request', {
      email
    });
    props.close();
  }

  return (
    <div className="login-modal-content">
      <h1>{props.text.reset_password}</h1>
      <div className="row">
        <div className="column registration-modal-column">
          <label htmlFor="email">{props.text.email_label}</label>
        </div>
        <div className="column registration-modal-column">
          <input id="email" onChange={emailChangeHandler} type="email" inputMode="email" pattern={emailPattern}/>
        </div>
      </div>
      <div className="login-modal-buttons">
        <button className="fless-button" onClick={sendLink}>{props.text.send_link}</button>
        <button className="fless-button" onClick={props.close}>{props.text.close}</button>
      </div>
    </div>
  );
}
