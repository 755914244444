import React from "react";

export function Association(props) {
  return (
    <div className="account-page">
      <div className="account-page-content">
        <h1>Reibung SE</h1>
        <div className="text-container extra-margin">{props.text.association_intro}</div>
        <h3>{props.text.association_data_title}</h3>
        <div className="text-container">
          <div className="data-container">
            <div className="data-row">
              <div>{props.text.association_data_name_label}</div>
              <div>{props.text.association_data_name}</div>
            </div>
            <div className="data-row">
              <div>{props.text.association_data_hq_label}</div>
              <div>{props.text.association_data_hq}</div>
            </div>
            <div className="data-row">
              <div>{props.text.association_data_email_label}</div>
              <div>{props.text.association_data_email}</div>
            </div>
            <div className="data-row">
              <div>{props.text.association_data_tel_label}</div>
              <div>{props.text.association_data_tel}</div>
            </div>
            <div className="data-row">
              <div>{props.text.association_data_reg_label}</div>
              <div>{props.text.association_data_reg}</div>
            </div>
            <div className="data-row">
              <div>{props.text.association_data_tax_label}</div>
              <div>{props.text.association_data_tax}</div>
            </div>
            <div className="data-row">
              <div>{props.text.association_data_bank_label}</div>
              <div>{props.text.association_data_bank}</div>
            </div>
          </div>
        </div>
        <h3>{props.text.association_application}</h3>
        <div className="text-container extra-margin">
          <div>{props.text.association_application_text}</div>
          <a href="https://forms.gle/QawgSnLQdbCwzHgz7">{props.text.association_application_link_label}</a>
          <div className="extra-margin">{props.text.association_application_note}</div>
          <div>{props.text.association_application_basic_membership}</div>
          <ul>
            <li>{props.text.association_application_basic_perks_1}</li>
            <li>{props.text.association_application_basic_perks_2}</li>
            <li>{props.text.association_application_basic_perks_3}</li>
          </ul>
          <div>{props.text.association_application_premium_membership}</div>
          <ul>
            <li>{props.text.association_application_premium_perks_1}</li>
            <li>{props.text.association_application_premium_perks_2}</li>
            <li>{props.text.association_application_premium_perks_3}</li>
            <li>{props.text.association_application_premium_perks_4}</li>
          </ul>
          <div>{props.text.mhssz_membership}</div>
          <ul>
            <li>{props.text.mhssz_perks_1}</li>
            <li>{props.text.mhssz_perks_2}</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
