export default {
  "en": {
    "main_title": "We are open!",
    "location": "Location",
    "address": "1149 Budapest, Kövér Lajos utca 23.",
    "home": "Home",
    "account": "Account",
    "email_label": "Email:",
    "password_label": "Password:",
    "name_label": "Full name:",
    "name_placeolder": "John Doe",
    "birth_label": "Birth date:",
    "logout": "Logout",
    "login": "Login",
    "forgot_password": "Forgot password?",
    "reset_password": "Reset password",
    "new_password": "New password:",
    "save": "Save",
    "send_link": "Send link",
    "registration": "Registration",
    "register": "Register",
    "close": "Close",
    "barcode_label": "Barcode:",
    "birthDate_label": "Birth date:",
    "passValid_label": "Pass valid until:",
    "remaining_label": "Remaining check-ins:",
    "invalid_login": "Invalid email or password.",
    "prices": "Prices",
    "tickets": "Tickets",
    "passes": "Passes",
    "registration_error": "There was an error with the registration.",
    "registration_success": "Successful registration.",
    "contact": "Contact",
    "phone": "Telephone: +36 30 126 8090",
    "email": "Email: info@fless.hu",
    "open": "Opening hours",
    "weekdays_MWF": "Monday - Wednesday - Thursday - Friday: 08:00-22:00",
    "weekdays_TT": "Tuesday: 14:00-22:00",
    "weekends": "Weekends: 10:00-20:00",
    "misc": "Miscellaneous",
    "shoes": "Climbing shoe rental",
    "chalk": "Chakbag and chalk rental",
    "shoesPrice": "700ft",
    "chalkPrice": "500ft",
    "holiday_open": "Holiday opening",
    "dec_24": "December 24th: 10:00-14:00",
    "dec_25": "December 25th: Closed",
    "dec_26": "December 26th: 10:00-20:00",
    "dec_27": "December 27th: 10:00-20:00",
    "dec_28": "December 28th: 14:00-20:00",
    "dec_29": "December 29th: 10:00-20:00",
    "dec_30": "December 30th: 10:00-20:00",
    "dec_31": "December 31st: 10:00-16:00",
    "jan_1": "Január 1st: Closed",
    "jan_2": "Január 2nd: 10:00-20:00",
    "taxIncluded": "All our prices include VAT.",
    "kids": "Kids wall",
    "kids_title": "Kids wall occupation",
    "kids_note": "Please vacate the kids wall for the duration of the activities.",
    "kids_monday": "Monday 15:00-18:30 - Bártfai Barbara",
    "kids_tuesday": "Tuesday 16:00-19:00 - Hópárduc Alapítvány",
    "kids_wednesday": "Wednesday 14:00-18:00 - AlpinMedix",
    "kids_thursday": "Thursday 15:00-18:30 - Bártfai Barbara",
    "kids_friday": "Friday 16:00-19:00 - Hópárduc Alapítvány",
    "kids_saturday": "Saturday 09:00-13:00 - Hópárduc Alapítvány",
    "schedule": "Trainings",
    "schedule_title": "fless! - Trainings",
    "schedule_notice": "Pre-registration is mandatory for all training sessions.",
    "camps": "Camps",
    "sensory": "Sensory climbing",
    "reibung_se": "Reibung SE",
    "association_intro": "We had two main goals in mind when we created our association. One is the promotion of outdoors bouldering as a leisure sport through the organization of regular trips. The other, taking the training of the next generation of hungarian competition climbers to the next level.",
    "association_data_title": "Association",
    "association_data_name_label": "Name:",
    "association_data_name": "Reibung Szabadidősport Egyesület",
    "association_data_hq_label": "Headquarters:",
    "association_data_hq": "2022 Tahitótfalu, Szállások út 13.",
    "association_data_email_label": "E-mail address:",
    "association_data_email": "reibungse@gmail.com",
    "association_data_tel_label": "Telephone number:",
    "association_data_tel": "+36709455234",
    "association_data_reg_label": "Registration number:",
    "association_data_reg": "13-02-0008453",
    "association_data_tax_label": "Tax number:",
    "association_data_tax": "19373177-1-13",
    "association_data_bank_label": "Bank account number:",
    "association_data_bank": "11714051-21461613",
    "association_application": "Application",
    "association_application_text": "You can join our association by filling out the form at the following link:",
    "association_application_link_label": "Reibung SE application form",
    "association_application_note": "Further information on joining will be emailed to you after filling out the application form.",
    "association_application_basic_membership": "Basic membership (10000 HUF/year):",
    "association_application_basic_perks_1": "Opportunity to participate in events organised by the association",
    "association_application_basic_perks_2": "Discounted Ocun crash pad renting (1000 HUF/day)",
    "association_application_basic_perks_3": "One time 5% discount on any pass purchased at the fless! boulder gym",
    "association_application_premium_membership": "Premium membership (20000 HUF/year):",
    "association_application_premium_perks_1": "5% discount on events organised by the association",
    "association_application_premium_perks_2": "Free Ocun crash pad renting (crash pads available in limited numbers)",
    "association_application_premium_perks_3": "One time 5% discount on any pass purchased at the fless! boulder gym",
    "association_application_premium_perks_4": "5% discount on any Boreal and Evolv climbing shoe purchased at our gym",
    "mhssz_membership": "MHSSZ membership card (3900 HUF/year):",
    "mhssz_perks_1": "Permission to climb at all designated Hungarian outdoor climbing spots",
    "mhssz_perks_2": "With the addition of a sports doctor's approval, you can get an official competition license that exempts you from the 2000 HUF fee of the one-time competition license for Hungarian Cup competitions",
  },
  "hu": {
    "main_title": "Megnyitottunk!",
    "location": "Megközelítés",
    "address": "1149 Budapest, Kövér Lajos utca 23.",
    "home": "Főoldal",
    "account": "Adataim",
    "email_label": "Email:",
    "password_label": "Jelszó:",
    "name_label": "Teljes név:",
    "name_placeolder": "Kovács István",
    "birth_label": "Születési dátum:",
    "logout": "Kijelentkezés",
    "login": "Bejelentkezés",
    "forgot_password": "Elfelejtetted a jelszavad?",
    "reset_password": "Jelszó csere",
    "new_password": "Új jelszó:",
    "save": "Mentés",
    "send_link": "Link küldése",
    "registration": "Regisztráció",
    "register": "Regisztráció",
    "close": "Bezár",
    "barcode_label": "Vonalkód:",
    "birthDate_label": "Születési dátum:",
    "passValid_label": "Bérlet érvényességi határidő:",
    "remaining_label": "Fennmaradó alkalmak száma:",
    "invalid_login": "Hibás email vagy jelszó.",
    "prices": "Áraink",
    "tickets": "Jegyek",
    "passes": "Bérletek",
    "registration_error": "Hiba történt a regisztráció során.",
    "registration_success": "Sikeres regisztráció.",
    "contact": "Kapcsolat",
    "phone": "Telefon: +36 30 126 8090",
    "email": "Email: info@fless.hu",
    "open": "Nyitvatartás",
    "weekdays_MWF": "Hétfő - Szerda - Csütörtök - Péntek: 08:00-22:00",
    "weekdays_TT": "Kedd: 14:00-22:00",
    "weekends": "Hétvégén: 10:00-20:00",
    "misc": "Egyebek",
    "shoes": "Mászócipő bérlés",
    "chalk": "Ziazsák és zia bérlés",
    "shoesPrice": "700ft",
    "chalkPrice": "500ft",
    "holiday_open": "Ünnepi nyitvatartás",
    "dec_24": "December 24: 10:00-14:00",
    "dec_25": "December 25: Zárva",
    "dec_26": "December 26: 10:00-20:00",
    "dec_27": "December 27: 10:00-20:00",
    "dec_28": "December 28: 14:00-20:00",
    "dec_29": "December 29: 10:00-20:00",
    "dec_30": "December 30: 10:00-20:00",
    "dec_31": "December 31: 10:00-16:00",
    "jan_1": "Január 1: Zárva",
    "jan_2": "Január 2: 10:00-20:00",
    "taxIncluded": "Áraink az ÁFA-t tartalmazzák.",
    "kids": "Gyerekfal",
    "kids_title": "Gyerekfal Foglaltság",
    "kids_note": "A fenti időszakokban a gyerekfalat kérjük szabadon hagyni a foglalkozások számára.",
    "kids_monday": "Hétfő 15:00-18:30 - Bártfai Barbara",
    "kids_tuesday": "Kedd 16:00-19:00 - Hópárduc Alapítvány",
    "kids_wednesday": "Szerda 14:00-18:00 - AlpinMedix",
    "kids_thursday": "Csütörtök 15:00-18:30 - Bártfai Barbara",
    "kids_friday": "Péntek 16:00-19:00 - Hópárduc Alapítvány",
    "kids_saturday": "Szombat 09:00-13:00 - Hópárduc Alapítvány",
    "schedule": "Órarend",
    "schedule_title": "fless! - Órarend",
    "schedule_notice": "Az edzésekre az előzetes bejelentkezés kötelező.",
    "camps": "Táborok",
    "sensory": "Szenzoros mászás",
    "reibung_se": "Reibung SE",
    "association_intro": "Egyesületünk létrehozásakor két fő cél lebegett a szemünk előtt. Az egyik a szikla boulderezés, mint szabadidősport népszerűsítése rendszeres külföldi boulder túrák szervezésén keresztül. A másik pedig a magyarországi sportmászás utánpótlás nevelésének új szintre emelése.",
    "association_data_title": "Szervezet",
    "association_data_name_label": "Név:",
    "association_data_name": "Reibung Szabadidősport Egyesület",
    "association_data_hq_label": "Székhely:",
    "association_data_hq": "2022 Tahitótfalu, Szállások út 13.",
    "association_data_email_label": "E-mail cím:",
    "association_data_email": "reibungse@gmail.com",
    "association_data_tel_label": "Telefonszám:",
    "association_data_tel": "+36709455234",
    "association_data_reg_label": "Nyilvántartási szám:",
    "association_data_reg": "13-02-0008453",
    "association_data_tax_label": "Adószám:",
    "association_data_tax": "19373177-1-13",
    "association_data_bank_label": "Bankszámlaszám:",
    "association_data_bank": "11714051-21461613",
    "association_application": "Jelentkezés",
    "association_application_text": "Az egyesületünkbe jelentkezni az alábbi jelentkezési lap kitöltésével lehet:",
    "association_application_link_label": "Reibung SE jelentkezési lap",
    "association_application_note": "A jelentkezési lap kitöltését követően emailben küldjük a jelentkezéssel kapcsolatos további teendőket.",
    "association_application_basic_membership": "Alap tagság (10000 Ft/év):",
    "association_application_basic_perks_1": "Részvételi lehetőség az egyesület által szervezett programokon",
    "association_application_basic_perks_2": "Kedvezményes Ocun crash pad bérlési lehetőség (1000 Ft/nap)",
    "association_application_basic_perks_3": "Egyszeri 5%-os engedmény a fless! Mászóteremben vásárolt bármely bérlet árából",
    "association_application_premium_membership": "Prémium tagság (20000 Ft/év):",
    "association_application_premium_perks_1": "5% kedvezménnyel vehetsz részt az egyesület által szervezett programokon",
    "association_application_premium_perks_2": "Ingyenes Ocun crash pad bérlési lehetőség (a szabad crash pad-ek függvényében)",
    "association_application_premium_perks_3": "Egyszeri 5%-os engedmény a fless! mászóteremben vásárolt bármely bérlet árából",
    "association_application_premium_perks_4": "Folyamatos 5% engedmény a fless! mászóteremben vásárolt Boreal és Evolv mászócipők árából",
    "mhssz_membership": "MHSSZ tagsági kártya (3900 Ft/év):",
    "mhssz_perks_1": "Jogosultságot szerzel az összes hazai sziklamászóhely használatára",
    "mhssz_perks_2": "Sportorvosi engedéllyel kibővítve hivatalos versenyengedélyhez jutsz, ami mentesít a Magyar Kupa versenyek eseti versenyengedélyének 2000 Ft-os költségétől",
  }
}
