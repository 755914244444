import React from "react";
import { Link } from "react-router-dom";
import { Login } from "./login";
import { Registration } from "./registration";

export function Navbar(props) {
  return (
    <nav className="navbar">
      <div className="navbar-left" >
        <Link className="navbar-link" to="/">{props.text.home}</Link>
        <Link className="navbar-link" to="/prices">{props.text.prices}</Link>
        <Link className="navbar-link" to="/schedule">{props.text.schedule}</Link>
        <Link className="navbar-link" to="/kids">{props.text.kids}</Link>
        <Link className="navbar-link wide-link-text" to="/sensory">{props.text.sensory}</Link>
        { false && props.language === "hu" && <Link className="navbar-link" to="/camps">{props.text.camps}</Link> }
        { props.token && <Link className="navbar-link" to="/account">{props.text.account}</Link> }
      </div>
      <div className="navbar-center" >
        <img className="navbar-logo" src="fless_logo.png" alt="fless-logo"/>
      </div>
      <div className="navbar-right" >
        <Link className="navbar-link" to="/reibung-se">{props.text.reibung_se}</Link>
        <button className="language fless-button" onClick={props.toggleLanguage}>EN / HU</button>
        <Login text={props.text} setUser={props.setUser} token={props.token} setToken={props.setToken} setError={props.setError} />
        {false && <Registration text={props.text} token={props.token} setError={props.setError} setSuccess={props.setSuccess}/>}
      </div>
    </nav>
  );
}
