import React from "react";
import { Link } from "react-router-dom";
import { Login } from "./login";
import { Registration } from "./registration";

export function MobileNavbar(props) {
  return (
    <nav className="navbar mobile-navbar">
      <div className="navbar-left" >
        <button className={props.menu ? "change hamburger" : "hamburger"} onClick={props.toggleMenu}>
          <div className="bar1" />
          <div className="bar2" />
          <div className="bar3" />
        </button>
      </div>
      <div className="navbar-center" >
        <img className="navbar-logo" src="fless_logo.png" alt="fless-logo"/>
      </div>
      {
        props.menu &&
        <div className="mobile-menu">
          <div className="navbar-top">
            <Link onClick={props.toggleMenu} className="navbar-link" to="/">{props.text.home}</Link>
            <Link onClick={props.toggleMenu} className="navbar-link" to="/prices">{props.text.prices}</Link>
            <Link onClick={props.toggleMenu} className="navbar-link" to="/schedule">{props.text.schedule}</Link>
            <Link onClick={props.toggleMenu} className="navbar-link" to="/kids">{props.text.kids}</Link>
            <Link onClick={props.toggleMenu} className="navbar-link wide-link-text" to="/sensory">{props.text.sensory}</Link>
            <Link onClick={props.toggleMenu} className="navbar-link" to="/reibung-se">{props.text.reibung_se}</Link>
            {false && props.language === "hu" && <Link className="navbar-link" to="/camps">{props.text.camps}</Link> }
            {props.token && <Link onClick={props.toggleMenu} className="navbar-link" to="/account">{props.text.account}</Link>}
          </div>
          <div className="navbar-bottom">
            <button className="fless-button language" onClick={props.toggleLanguage}>EN / HU</button>
            <Login text={props.text} setUser={props.setUser} token={props.token} setToken={props.setToken}/>
            {false && <Registration text={props.text} token={props.token} setError={props.setError}
                           setSuccess={props.setSuccess}/>}
          </div>
        </div>
      }
    </nav>
  );
}
