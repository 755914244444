import React from "react";
import axios from "../request";
import { emailPattern } from "../utils/email-pattern";
import { RequestPasswordReset } from "./password-reset-request";

export function Login(props) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [modal, toggleModalProp] = React.useState(false);
  const [modalContent, toggleModalContentProp] = React.useState("login");

  function emailChangeHandler(event) {
    setEmail(event.target.value);
  }

  function passwordChangeHandler(event) {
    setPassword(event.target.value);
  }

  async function loginHandler() {
    try {
      const response = await axios.post('/login', {
        email,
        password
      });
      localStorage.setItem("token", response.data.token);
      props.setToken(response.data.token);
      props.setUser(response.data.user);
      resetModal();
    } catch (error) {
      console.error(error);
      props.setError(`${props.text.invalid_login}`)
    }
  }

  function logoutHandler() {
    localStorage.setItem("token", "");
    props.setToken("");
    props.setUser("");
  }

  function toggleModal() {
    if (modal) {
      toggleModalProp(false);
    } else {
      toggleModalProp(true);
    }
  }

  function resetModal() {
    toggleModalProp(false);
    toggleModalContentProp("login");
  }

  function toggleModalContent() {
    if (modalContent === "login") {
      toggleModalContentProp("reset");
    } else {
      toggleModalContentProp("login");
    }
  }

  return (
    <div className="container">
      <div className={modal ? "login-modal shown" : "login-modal"}>
        { modalContent === "login" &&
          <div className="login-modal-content">
            <h2 className="login-modal-title">{props.text.login}</h2>
            <div className="row">
              <div className="column registration-modal-column">
                <label htmlFor="email">{props.text.email_label}</label>
                <label htmlFor="password">{props.text.password_label}</label>
              </div>
              <div className="column registration-modal-column">
                <input id="email" onChange={emailChangeHandler} type="email" inputMode="email" pattern={emailPattern}/>
                <input id="password" onChange={passwordChangeHandler} type="password"/>
              </div>
            </div>
            <a className="fless-link" onClick={toggleModalContent}>{props.text.forgot_password}</a>
            <div className="login-modal-buttons">
              <button className="fless-button" type="submit" onClick={loginHandler}>{props.text.login}</button>
              <button className="fless-button" onClick={toggleModal}>{props.text.close}</button>
            </div>
          </div>
        }
        { modalContent === "reset" &&
          <RequestPasswordReset close={resetModal} text={props.text} />
        }
      </div>
      {
        !props.token && <button className="fless-button" onClick={toggleModal}>{props.text.login}</button>
      }
      {
        props.token && <button className="fless-button" onClick={logoutHandler}>{props.text.logout}</button>
      }
    </div>
  );
}
