import React from "react";

export function Home(props) {
  return (
    <div>
      <div className="home-jumbo">
        <div className="home-overlay">
          <div className="fless-logo" />
          {false && <h1 className="main-title">{props.text.main_title}</h1>}
        </div>
      </div>
      <div className="about">
        <div className="column about-info">
          <h1>{props.text.location}</h1>
          <p>{props.text.address}</p>
        </div>
        <div className="column about-info">
          <h1>{props.text.open}</h1>
          <p>{props.text.weekdays_MWF}</p>
          <p>{props.text.weekdays_TT}</p>
          <p>{props.text.weekends}</p>
        </div>
        {false && <div className="column about-info">
          <h1>{props.text.holiday_open}</h1>
          <p>{props.text.dec_24}</p>
          <p>{props.text.dec_25}</p>
          <p>{props.text.dec_26}</p>
          <p>{props.text.dec_27}</p>
          <p>{props.text.dec_28}</p>
          <p>{props.text.dec_29}</p>
          <p>{props.text.dec_30}</p>
          <p>{props.text.dec_31}</p>
          <p>{props.text.jan_1}</p>
          <p>{props.text.jan_2}</p>
        </div>}
        <div className="column about-info">
          <h1>{props.text.contact}</h1>
          <p>{props.text.email}</p>
          <p>{props.text.phone}</p>
          <div className="contact-container">
            <a className="facebook" href="https://www.facebook.com/flessbudapest/" rel="noreferrer noopener"
               target="_blank" />
            <a className="instagram" href="https://www.instagram.com/flessbudapest/" rel="noreferrer noopener"
               target="_blank" />
          </div>
        </div>
      </div>
      <div className="map-container">
        <div className="map">Map</div>
      </div>
    </div>
  );
}
