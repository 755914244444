import React from "react";
import axios from "../request";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { emailPattern } from "../utils/email-pattern";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import hu from "date-fns/locale/hu";
registerLocale("hu", hu);
setDefaultLocale("hu");

export function Registration(props) {
  const initialState = {
    email: "",
    name: "",
    password: ""
  }

  const [{ email, password, name }, setState] = React.useState(initialState);
  const [birthDate, setBirthDate] = React.useState("");
  const [modal, toggleModalProp] = React.useState(false);
  const [mobile, setMobile] = React.useState(false);

  React.useEffect(() => {
    const agent = (navigator.userAgent||navigator.vendor||window.opera);
    if(agent.match(/Android|iPhone|BlackBerry|PlayBook|BB10|iPad|iPod|Opera Mini|IEMobile|Nexus|Kindle Fire|PalmSource|Palm/gi)){
      setMobile(true);
    }
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  function reset() {
    setState({ ...initialState });
  }

  function clickHandler() {
    if (email && password && birthDate) {
      axios.post('/register', {
        email,
        password,
        name,
        birthDate
      })
        .then(() => {
          toggleModal();
          reset();
          setBirthDate(initialState.birthDate);
          props.setSuccess(`${props.text.registration_success}`);
        })
        .catch(error => {
          props.setError(`${props.text.registration_error}`);
        });
    } else {
      console.error("Email, password and birth date fields are required.");
    }
  }

  function birthDateChangeHandler(event) {
    setBirthDate(event.target.value);
  }

  function toggleModal() {
    if (modal) {
      toggleModalProp(false);
    } else {
      toggleModalProp(true);
    }
  }

  return (
    <div className="container">
      <div className={modal ? "registration-modal shown" : "registration-modal"}>
        <div className="registration-modal-content">
          <h2 className="registration-modal-title">{props.text.registration}</h2>
          <div className="row">
            <div className="column registration-modal-column">
              <label htmlFor="email">{props.text.email_label}</label>
              <label htmlFor="password">{props.text.password_label}</label>
              <label htmlFor="name">{props.text.name_label}</label>
              <label htmlFor="birth">{props.text.birth_label}</label>
            </div>
            <div className="column registration-modal-column">
              <input required value={email} name="email" onChange={handleChange} type="email" inputMode="email" pattern={emailPattern}/>
              <input required value={password} name="password" onChange={handleChange} type="password"/>
              <input required value={name} name="name" placeholder={props.text.name_placeolder} onChange={handleChange} type="text"/>
              {!mobile && <DatePicker selected={birthDate} onChange={setBirthDate} locale="hu" dateFormat="yyyy.MM.dd"/>}
              {mobile && <input required name="birth" onChange={birthDateChangeHandler} type="date"/>}
            </div>
          </div>
          <div className="registration-modal-button-container">
            <button className="fless-button" type="submit" onClick={clickHandler}>{props.text.register}</button>
            <button className="fless-button" onClick={toggleModal}>{props.text.close}</button>
          </div>
        </div>
      </div>
      {
        !props.token && <button className="fless-button" onClick={toggleModal}>{props.text.register}</button>
      }
    </div>
  );
}
