import React from "react";
import { useHistory } from "react-router";
import axios from "../request";

export function PasswordReset(props) {
  const history = useHistory();
  const [password, setPassword] = React.useState("");

  function useQuery() {
    return new URLSearchParams(props.useLocation().search);
  }
  const token = useQuery().get("token");

  function passwordChangeHandler(event) {
    setPassword(event.target.value);
  }

  function sendPassword() {
    axios.post('/password-reset', {
      password,
      token
    })
      .then(() => {
        history.push(`/home`);
      });
  }

  return (
    <div className="account-page">
      <div className="account-page-content">
        <div className="column">
          <h1>{props.text.reset_password}</h1>
          <label htmlFor="password">{props.text.password_label}</label>
          <input id="password" type="password" onChange={passwordChangeHandler} />
          <button className="fless-button" onClick={sendPassword}>{props.text.save}</button>
        </div>
      </div>
    </div>
  );
}
