import React from "react";

export function Error(props) {

  React.useEffect(() => {
    setTimeout(close, 10000)
  })

  function close() {
    return props.setError("")
  }

  return (
    <div className="error-container">
      <div className="error-row" onClick={close}>
        <div>{props.error}</div>
      </div>
    </div>
  );
}
