import axios from "axios";

const api = axios.create({
  baseURL: `https://${process.env.REACT_APP_SERVER}/api/v1`
});

const token = localStorage.getItem("token");
if (token) {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
}

export default api;
