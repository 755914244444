import React from "react";

export function Sensory(props) {
  return (
    <div className="schedule-page">
      <div className="image-page">
        <div className="schedule-image-container">
          <img id="sensory-climbing" className="schedule-image" src="kids_climbing.jpg" alt="sensory climbing"/>
        </div>
      </div>
    </div>
  );
}
