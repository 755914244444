import React from "react";
import { format } from "date-fns";

export function Account(props) {
  return (
    <div className="account-page">
      <div className="account-page-content">
        <h1>{props.text.account}</h1>
        <div className="account-data-container">
          <div className="column account-data-row">
            <div>{props.text.email_label}</div>
            <div>{props.user.email || "-"}</div>
          </div>
          <div className="column account-data-row">
            <div>{props.text.name_label}</div>
            <div>{props.user.name || "-"}</div>
          </div>
          <div className="column account-data-row">
            <div>{props.text.barcode_label}</div>
            <div>{props.user.barcode || "-"}</div>
          </div>
          <div className="column account-data-row">
            <div>{props.text.birthDate_label}</div>
            <div>{props.user.birthDate || "-"}</div>
          </div>
          <div className="column account-data-row">
            <div>{props.text.passValid_label}</div>
            <div>{props.user.pass && props.user.pass.passValid ? format(new Date(props.user.pass.passValid), "yyyy-MM-dd") : "-"}</div>
          </div>
          <div className="column account-data-row">
            <div>{props.text.remaining_label}</div>
            <div>{props.user.pass && props.user.pass.remaining ? props.user.pass.remaining : "-"}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
