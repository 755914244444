export default {
  hu: {
    tickets: [
      {
        name: "Felnőtt belépő, csúcsidő",
        price: "3000 HUF",
        details: "Hétköznap 16:00 után és hétvégén és ünnepnapokon egész nap"
      },
      {
        name: "Felnőtt belépő, mellékidő",
        price: "2700 HUF",
        details: "Hétköznap 16:00 előtt"
      },
      {
        name: "Felnőtt belépő, délelőtt",
        price: "2200 HUF",
        details: "Hétköznap 12:00 előtt"
      },
      {
        name: "Diák/pedagógus belépő, csúcsidő",
        price: "2300 HUF",
        details: "Hétköznap 16:00 után és hétvégén és ünnepnapokon egész nap, diák/pedagógus igazolvánnyal, diákoknak 26 éves kor alatt"
      },
      {
        name: "Diák/pedagógus belépő, mellékidő",
        price: "1900 HUF",
        details: "Hétköznap 16:00 előtt, diák/pedagógus igazolvánnyal, diákoknak 26 éves kor alatt"
      },
      {
        name: "Diák/pedagógus belépő, délelőtt",
        price: "1700 HUF",
        details: "Hétköznap 12:00 előtt, diák/pedagógus igazolvánnyal, diákoknak 26 éves kor alatt"
      },
      {
        name: "Gyerek belépő",
        price: "1700 HUF",
        details: "14 éves kor alatt"
      },
      {
        name: "Flow belépő",
        price: "1700 HUF",
        details: "Zárás előtt 1 órával"
      }
    ],
      passes: [
      {
        name: "Felnőtt 10 alkalmas bérlet, csúcsidő",
        price: "27000 HUF",
        details: "2 hónapig érvényes"
      },
      {
        name: "Felnőtt 10 alkalmas bérlet, mellékidő",
        price: "24500 HUF",
        details: "2 hónapig érvényes"
      },
      {
        name: "Felnőtt 10 alkalmas bérlet, délelőtt",
        price: "20000 HUF",
        details: "2 hónapig érvényes"
      },
      {
        name: "Felnőtt havi korlátlan bérlet",
        price: "24000 HUF"
      },
      {
        name: "Felnőtt negyedéves korlátlan bérlet",
        price: "64000 HUF"
      },
      {
        name: "Felnőtt féléves korlátlan bérlet",
        price: "112000 HUF"
      },
      {
        name: "Felnőtt éves korlátlan bérlet",
        price: "189000 HUF"
      },
      {
        name: "Diák/pedagógus 10 alkalmas bérlet, csúcsidő",
        price: "20500 HUF",
        details: "2 hónapig érvényes"
      },
      {
        name: "Diák/pedagógus 10 alkalmas bérlet, mellékidő",
        price: "17000 HUF",
        details: "2 hónapig érvényes"
      },
      {
        name: "Diák/pedagógus 10 alkalmas bérlet, délelőtt",
        price: "15500 HUF",
        details: "2 hónapig érvényes"
      },
      {
        name: "Diák/pedagógus havi korlátlan bérlet",
        price: "17500 HUF"
      },
      {
        name: "Diák/pedagógus negyedéves korlátlan bérlet",
        price: "47000 HUF"
      },
      {
        name: "Diák/pedagógus féléves korlátlan bérlet",
        price: "84000 HUF"
      },
      {
        name: "Diák/pedagógus éves korlátlan bérlet",
        price: "147000 HUF"
      },
      {
        name: "Gyerek 10 alkalmas bérlet",
        price: "15000 HUF",
        details: "2 hónapig érvényes"
      },
      {
        name: "Gyerek havi korlátlan bérlet",
        price: "13500 HUF"
      },
      {
        name: "Gyerek negyedéves korlátlan bérlet",
        price: "38000 HUF"
      },
      {
        name: "Gyerek féléves korlátlan bérlet",
        price: "67000 HUF"
      },
      {
        name: "Gyerek éves korlátlan bérlet",
        price: "108000 HUF"
      }
    ]
  },
  en: {
    tickets: [
      {
        name: "Adult daily pass, peak hours",
        price: "3000 HUF",
        details: "From 16:00 on weekdays and all day on weekends and holidays"
      },
      {
        name: "Adult daily pass, early afternoon",
        price: "2700 HUF",
        details: "From 12:00 to 16:00 on weekdays"
      },
      {
        name: "Adult daily pass, before noon",
        price: "2200 HUF",
        details: "Before 12:00 on weekdays"
      },
      {
        name: "Student/teacher daily pass, peak hours",
        price: "2300 HUF",
        details: "From 16:00 on weekdays and all day on weekends and holidays, with student/teacher card, 26 years old and below for students"
      },
      {
        name: "Student/teacher daily pass, early afternoon",
        price: "1900 HUF",
        details: "From 12:00 to 16:00 on weekdays, with student/teacher card, 26 years old and below for students"
      },
      {
        name: "Student/teacher daily pass, before noon",
        price: "1700 HUF",
        details: "Before 12:00 on weekdays, with student/teacher card, 26 years old and below for students"
      },
      {
        name: "Kids daily pass",
        price: "1700 HUF",
        details: "14 years old and below"
      },
      {
        name: "Flow pass",
        price: "1700 HUF",
        details: "One hour before closing"
      }
    ],
      passes: [
      {
        name: "Adult 10 day pass, peak hours",
        price: "27000 HUF",
        details: "Valid for 2 months"
      },
      {
        name: "Adult 10 day pass, early afternoon",
        price: "24500 HUF",
        details: "Valid for 2 months"
      },
      {
        name: "Adult 10 day pass, before noon",
        price: "20000 HUF",
        details: "Valid for 2 months"
      },
      {
        name: "Adult monthly pass",
        price: "24000 HUF"
      },
      {
        name: "Adult quarterly pass",
        price: "64000 HUF"
      },
      {
        name: "Adult 6 month pass",
        price: "112000 HUF"
      },
      {
        name: "Adult yearly pass",
        price: "189000 HUF"
      },
      {
        name: "Student/teacher 10 day pass, peak hours",
        price: "20500 HUF",
        details: "Valid for 2 months"
      },
      {
        name: "Student/teacher 10 day pass, early afternoon",
        price: "17000 HUF",
        details: "Valid for 2 months"
      },
      {
        name: "Student/teacher 10 day pass, before noon",
        price: "15500 HUF",
        details: "Valid for 2 months"
      },
      {
        name: "Student/teacher monthly pass",
        price: "17500 HUF"
      },
      {
        name: "Student/teacher quarterly pass",
        price: "47000 HUF"
      },
      {
        name: "Student/teacher 6 month pass",
        price: "84000 HUF"
      },
      {
        name: "Student/teacher yearly pass",
        price: "147000 HUF"
      },
      {
        name: "Kids 10 day pass",
        price: "15000 HUF",
        details: "Valid for 2 months"
      },
      {
        name: "Kids monthly pass",
        price: "13500 HUF"
      },
      {
        name: "Kids quarterly pass",
        price: "38000 HUF"
      },
      {
        name: "Kids 6 month pass",
        price: "67000 HUF"
      },
      {
        name: "Kids yearly pass",
        price: "108000 HUF"
      }
    ]
  }
}
