import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import './App.css';
import axios from "./request";
import { Home } from "./routes/home";
import { Account } from "./routes/account";
import { PasswordReset } from "./routes/password-reset";
import { Navbar } from "./routes/navbar";
import { MobileNavbar } from "./routes/mobile-navbar";
import languages from "./language/language";
import priceData from "./language/prices";
import { Error } from "./routes/error";
import { Prices } from "./routes/prices";
import { Schedule } from "./routes/schedule";
import { Kids } from "./routes/kids";
import { Camps } from "./routes/camps";
import {Sensory} from "./routes/sensory";
import {Association} from "./routes/reibung-se";

function App() {
  const [user, setUser] = React.useState("");
  const [token, setToken] = React.useState(localStorage.getItem("token"));
  const [language, setLanguage] = React.useState("hu");
  const [text, setText] = React.useState(languages.hu);
  const [prices, setPrices] = React.useState(priceData.hu);
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");

  function toggleMobileMenu() {
    if (mobileMenu) {
      setMobileMenu(false);
    } else {
      setMobileMenu(true);
    }
  }

  function toggleLanguage() {
    if (language === "hu") {
      setText(languages.en);
      setPrices(priceData.en);
      setLanguage("en");
    }
    if (language === "en") {
      setText(languages.hu);
      setPrices(priceData.hu);
      setLanguage("hu");
    }
  }

  React.useEffect(() => {
    if (token && !user) {
      axios.get('/user')
        .then(response => { setUser(response.data); })
        .catch(console.error);
    }
  }, []);

  return (
    <div className={mobileMenu ? "App fixed" : "App"}>
      <Router>
        { mobileMenu && <div className="menu-overlay"/> }
        <Navbar text={text} toggleLanguage={toggleLanguage} token={token} setUser={setUser} setToken={setToken} setError={setError} setSuccess={setSuccess} language={language} />
        <MobileNavbar text={text} toggleLanguage={toggleLanguage} token={token} setUser={setUser} setToken={setToken} toggleMenu={toggleMobileMenu} menu={mobileMenu} setError={setError} setSuccess={setSuccess} language={language} />
        <Switch>
          <Route exact path="/">
            <Home text={text} setUser={setUser} token={token} setToken={setToken} />
          </Route>
          <Route exact path="/home">
            <Redirect to="/" />
          </Route>
          <Route exact path="/prices">
            <Prices text={text} prices={prices} />
          </Route>
          <Route exact path="/schedule">
            <Schedule language={language} text={text} />
          </Route>
          <Route exact path="/kids">
            <Kids language={language} text={text} />
          </Route>
          <Route exact path="/sensory">
            <Sensory language={language} text={text} />
          </Route>
          <Route exact path="/reibung-se">
            <Association language={language} text={text} />
          </Route>
          { false && <Route exact path="/camps">
            <Camps language={language} text={text}/>
          </Route> }
          <Route exact path="/password-reset">
            <PasswordReset text={text} useLocation={useLocation} />
          </Route>

          { !token && <Redirect to="/" /> }
          <Route exact path="/account">
            <Account text={text} user={user} setUser={setUser} />
          </Route>
        </Switch>
      </Router>
      { error && <Error error={error} setError={setError}/> }
      { success && <Error error={success} setError={setSuccess}/> }
    </div>
  );
}

export default App;
