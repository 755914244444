import React from "react";

export function Kids(props) {
  return (
    <div className="kids-page">
      <h1>{props.text.kids_title}</h1>
      <div className="kids-schedule-container">
        <p>{props.text.kids_monday}</p>
        <p>{props.text.kids_tuesday}</p>
        <p>{props.text.kids_wednesday}</p>
        <p>{props.text.kids_thursday}</p>
        <p>{props.text.kids_friday}</p>
        <p>{props.text.kids_saturday}</p>
      </div>
      <i>{props.text.kids_note}</i>
    </div>
  );
}
