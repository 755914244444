import React from "react";

export function Schedule(props) {
  return (
    <div className="schedule-page">
      <div className="schedule-container">
        <h1>{props.text.schedule_title}</h1>
        <div className="schedule-image-container">
          <img id="schedule-image" className="schedule-image" src="fless_orarend_2023.png" alt="orarend / schedule"/>
          {/* props.language === "hu" && <img className="schedule-image" src="fless_orarend.png" alt="schedule"/> */}
          {/* props.language === "en" && <img className="schedule-image" src="fless_schedule.png" alt="schedule"/> */}
        </div>
        <h3>
          {props.text.schedule_notice}
        </h3>
        <div className="schedule-contact-container">
          <div className="schedule-contact">
            <p>Balázs András</p>
            <p>–</p>
            <p className="contact-info">
              <a href="mailto:bajanos93@gmail.com">bajanos93@gmail.com</a>
              <a className="phone-number" href="tel:+36706704162">+36706704162</a>
            </p>
          </div>
          <div className="schedule-contact">
            <p>Bártfai Barbara</p>
            <p>–</p>
            <p className="contact-info"><a href="mailto:bbartfai@gmail.com">bbartfai@gmail.com</a></p>
          </div>
          <div className="schedule-contact">
            <p>Bártfai Péter</p>
            <p>–</p>
            <p className="contact-info">
              <a href="mailto:kasbiel79@gmail.com">kasbiel79@gmail.com</a>
              <a className="phone-number" href="tel:+36309857574">+36309857574</a>
            </p>
          </div>
          <div className="schedule-contact">
            <p>Király Norci</p>
            <p>–</p>
            <p className="contact-info"><a href="mailto:kiralynorci@gmail.com">kiralynorci@gmail.com</a></p>
          </div>
          <div className="schedule-contact">
            <p>Lality Levente</p>
            <p>–</p>
            <p className="contact-info"><a className="phone-number" href="tel:+36705781300">+36705781300</a></p>
          </div>
          <div className="schedule-contact">
            <p>Kalocsai Jázmin</p>
            <p>–</p>
            <p className="contact-info"><a href="mailto:jazmin@fless.hu">jazmin@fless.hu</a></p>
          </div>
          <div className="schedule-contact">
            <p>Petró Péter</p>
            <p>–</p>
            <p className="contact-info"><a href="https://www.facebook.com/peter.petro.12">Facebook</a></p>
          </div>
          <div className="schedule-contact">
            <p>Tóth Ákos</p>
            <p>–</p>
            <p className="contact-info">
              <a href="https://www.facebook.com/bouldermentor/">Facebook</a>
              <a className="phone-number" href="tel:+36306332144">+36306332144</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
