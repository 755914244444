import React from "react";

export function Prices(props) {
  const tickets = props.prices.tickets.map(item => {
    return (
      <div className="item-data-row">
        <div>{item.name}</div>
        <div>{item.price}</div>
        <div>{item.details}</div>
      </div>
    );
  });

  const passes = props.prices.passes.map(item => {
    return (
      <div className="item-data-row">
        <div>{item.name}</div>
        <div>{item.price}</div>
        <div>{item.details}</div>
      </div>
    );
  });

  return (
    <div className="account-page">
      <div className="account-page-content">
        <h1>{props.text.prices}</h1>
        <h3>{props.text.tickets}</h3>
        <div className="item-data-container">
          { tickets }
        </div>
        <h3>{props.text.passes}</h3>
        <div className="item-data-container">
          { passes }
        </div>
        <h3>{props.text.misc}</h3>
        <div className="item-data-container">
          <div className="item-data-row">
            <div>{props.text.shoes}</div>
            <div>{props.text.shoesPrice}</div>
            <div/>
          </div>
          <div className="item-data-row">
            <div>{props.text.chalk}</div>
            <div>{props.text.chalkPrice}</div>
            <div/>
          </div>
        </div>
        <p className="item-note">{props.text.taxIncluded}</p>
      </div>
    </div>
  );
}
